.dxbl-carousel {
    --dxbl-carousel-background-color: #{$dx-carousel-background-color};
    --dxbl-carousel-button-border-radius: #{$dx-carousel-button-border-radius};
    --dxbl-carousel-button-border-width: #{$dx-carousel-button-border-width};
    --dxbl-carousel-button-background: #{$dx-carousel-button-background};
    --dxbl-carousel-button-background-opacity: #{$dx-carousel-button-background-opacity};
    --dxbl-carousel-button-background-hover: #{$dx-carousel-button-background-hover};
    --dxbl-carousel-button-background-opacity-hover: #{$dx-carousel-button-background-opacity-hover};
    --dxbl-carousel-button-background-disabled: #{$dx-carousel-button-background-disabled};
    --dxbl-carousel-button-background-opacity-disabled: #{$dx-carousel-button-background-opacity-disabled};
    --dxbl-carousel-button-icon-color: #{$dx-carousel-button-icon-color};
    --dxbl-carousel-button-icon-color-disabled: #{$dx-carousel-button-icon-color-disabled};
    --dxbl-carousel-button-icon-color-hover: #{$dx-carousel-button-icon-color-hover};
    --dxbl-carousel-button-padding-lg: #{$dx-carousel-button-padding-lg};
    --dxbl-carousel-button-padding-sm: #{$dx-carousel-button-padding-sm};
    --dxbl-carousel-button-padding: #{$dx-carousel-button-padding};
    --dxbl-carousel-button-icon-size-lg: #{$dx-carousel-button-icon-size-lg};
    --dxbl-carousel-button-icon-size-sm: #{$dx-carousel-button-icon-size-sm};
    --dxbl-carousel-button-icon-size: #{$dx-carousel-button-icon-size};
    --dxbl-carousel-pages-padding-y: #{$dx-carousel-pages-padding-y};
    --dxbl-carousel-pages-padding-x: #{$dx-carousel-pages-padding-x};
    --dxbl-carousel-pages-padding-y-sm: #{$dx-carousel-pages-padding-y-sm};
    --dxbl-carousel-pages-padding-x-sm: #{$dx-carousel-pages-padding-x-sm};
    --dxbl-carousel-pages-padding-y-lg: #{$dx-carousel-pages-padding-y-lg};
    --dxbl-carousel-pages-padding-x-lg: #{$dx-carousel-pages-padding-x-lg};
    --dxbl-carousel-pages-background-color: #{$dx-carousel-pages-background-color};
    --dxbl-carousel-pages-background-opacity: #{$dx-carousel-pages-background-opacity};
    --dxbl-carousel-pages-background-border-radius: #{$dx-carousel-pages-background-border-radius};
    --dxbl-carousel-page-size: #{$dx-carousel-page-size};
    --dxbl-carousel-page-size-lg: #{$dx-carousel-page-size-lg};
    --dxbl-carousel-page-size-sm: #{$dx-carousel-page-size-sm};
    --dxbl-carousel-page-background-color: #{$dx-carousel-page-background-color};
    --dxbl-carousel-page-margin: #{$dx-carousel-page-margin};
    --dxbl-carousel-page-opacity: #{$dx-carousel-page-opacity};
    --dxbl-carousel-page-hover-opacity: #{$dx-carousel-page-hover-opacity};
    --dxbl-carousel-page-hover-opacity-active: #{$dx-carousel-page-hover-opacity-active};
    --dxbl-carousel-page-active-background-color: #{$dx-carousel-page-active-background-color};
    --dxbl-carousel-overlay-color: #{$dx-carousel-overlay-color};
    --dxbl-carousel-overlay-opacity: #{$dx-carousel-overlay-opacity};
    --dxbl-carousel-focus-outline-width: #{$dx-carousel-focus-outline-width};
    --dxbl-carousel-focus-outline-color: #{$dx-carousel-focus-outline-color};
    --dxbl-carousel-margin-sm: #{$dx-carousel-margin-sm};
    --dxbl-carousel-margin: #{$dx-carousel-margin};
    --dxbl-carousel-margin-lg: #{$dx-carousel-margin-lg};

    overflow: hidden;
    position: relative;
    white-space: nowrap;
    display: block;
    user-select: none;
    background-color: var(--dxbl-carousel-background-color);
    touch-action: none;

    &.dxbl-disabled-state {
        pointer-events: none;
    }

    .dxbl-overlay.dxbl-disabled-state {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--dxbl-carousel-overlay-color);
        opacity: var(--dxbl-carousel-overlay-opacity);
    }

    &:focus-visible:not([data-dx-focus-hidden]) {
        outline: var(--dxbl-carousel-focus-outline-width) solid var(--dxbl-carousel-focus-outline-color);
    }

    .dxbl-carousel-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }

    .dxbl-carousel-arrow-button {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 25px;
        position: absolute;
        opacity: var(--dxbl-carousel-button-background-opacity);
        pointer-events: all;
    }

    .dxbl-btn.dxbl-btn-secondary {
        --dxbl-btn-bg: var(--dxbl-carousel-button-background);
        --dxbl-btn-hover-bg: var(--dxbl-carousel-button-background-hover);
        --dxbl-btn-border-width: var(--dxbl-carousel-button-border-width);
        --dxbl-btn-border-radius: var(--dxbl-carousel-button-border-radius);
        --dxbl-btn-disabled-bg: var(--dxbl-carousel-button-background-disabled);
        --dxbl-btn-disabled-opacity: var(--dxbl-carousel-button-background-opacity-disabled);
        --dxbl-btn-padding-x: var(--dxbl-carousel-button-padding);
        --dxbl-btn-padding-y: var(--dxbl-carousel-button-padding);
    }

    &.dxbl-sm {
        .dxbl-btn.dxbl-btn-secondary {
            --dxbl-btn-padding-x: var(--dxbl-carousel-button-padding-sm);
            --dxbl-btn-padding-y: var(--dxbl-carousel-button-padding-sm);

            .dxbl-image {
                width: var(--dxbl-carousel-button-icon-size-sm);
                height: var(--dxbl-carousel-button-icon-size-sm);
            }
        }

        .dxbl-carousel-arrow-button.dxbl-left-arrow {
            left: var(--dxbl-carousel-margin-sm);
        }

        .dxbl-carousel-arrow-button.dxbl-right-arrow {
            right: var(--dxbl-carousel-margin-sm);
        }

        .dxbl-carousel-pages {
            bottom: var(--dxbl-carousel-margin-sm);

            .dxbl-carousel-page {
                width: var(--dxbl-carousel-page-size-sm);
                height: var(--dxbl-carousel-page-size-sm);
            }

            .dxbl-carousel-pages-inner-container {
                padding: var(--dxbl-carousel-pages-padding-y-sm) var(--dxbl-carousel-pages-padding-x-sm);
            }
        }
    }

    &.dxbl-lg {
        .dxbl-btn.dxbl-btn-secondary {
            --dxbl-btn-padding-x: var(--dxbl-carousel-button-padding-lg);
            --dxbl-btn-padding-y: var(--dxbl-carousel-button-padding-lg);

            .dxbl-image {
                width: var(--dxbl-carousel-button-icon-size-lg);
                height: var(--dxbl-carousel-button-icon-size-lg);
            }
        }

        .dxbl-carousel-arrow-button.dxbl-left-arrow {
            left: var(--dxbl-carousel-margin-lg);
        }

        .dxbl-carousel-arrow-button.dxbl-right-arrow {
            right: var(--dxbl-carousel-margin-lg);
        }

        .dxbl-carousel-pages {
            bottom: var(--dxbl-carousel-margin-lg);

            .dxbl-carousel-page {
                width: var(--dxbl-carousel-page-size-lg);
                height: var(--dxbl-carousel-page-size-lg);
            }

            .dxbl-carousel-pages-inner-container {
                padding: var(--dxbl-carousel-pages-padding-y-lg) var(--dxbl-carousel-pages-padding-x-lg);
            }
        }
    }

    .dxbl-carousel-arrow-button:not([disabled]):hover {
        opacity: var(--dxbl-carousel-button-background-opacity-hover);

        .dxbl-image {
            color: var(--dxbl-carousel-button-icon-color-hover);
        }
    }

    .dxbl-carousel-arrow-button:disabled {
        .dxbl-image {
            color: var(--dxbl-carousel-button-icon-color-disabled);
        }
    }

    .dxbl-carousel-arrow-button.dxbl-disabled-state {
        pointer-events: none;
    }

    .dxbl-image {
        color: var(--dxbl-carousel-button-icon-color);
        width: var(--dxbl-carousel-button-icon-size);
        height: var(--dxbl-carousel-button-icon-size);
        margin: 0;
    }

    .dxbl-carousel-arrow-button.dxbl-left-arrow {
        left: var(--dxbl-carousel-margin);
    }

    .dxbl-carousel-arrow-button.dxbl-right-arrow {
        right: var(--dxbl-carousel-margin);
    }

    .dxbl-carousel-scrollview-buttons {
        transition: opacity 0.5s ease;
    }

    .dxbl-carousel-scrollview-buttons.dxbl-hide-buttons {
        opacity: 0;
    }

    .dxbl-carousel-scrollview-buttons.dxbl-show-buttons {
        opacity: 1;
    }

    .dxbl-carousel-substrate-button {
        position: absolute;
        top: 0;
        width: 35%;
        height: 100%;
        background: transparent;
        align-items: center;
        display: flex;
    }

    .dxbl-carousel-substrate-button.dxbl-left-arrow {
        left: 0;
    }

    .dxbl-carousel-substrate-button.dxbl-right-arrow {
        right: 0;
    }

    .dxbl-carousel-disable-events {
        pointer-events: none;
    }

    .dxbl-carousel-item {
        height: 100%;
        width: 100%;
        overflow: hidden;
        flex-shrink: 0;
        text-align: center;
    }

    .dxbl-carousel-item-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .dxbl-carousel-item-content > * {
        flex-grow: 1;
        width: 100%;
        height: 100%;
    }

    .dxbl-fit-proportional > * {
        object-fit: contain;
    }

    .dxbl-fit-crop > * {
        object-fit: cover;
    }

    .dxbl-carousel-pages {
        width: 100%;
        bottom: var(--dxbl-carousel-margin);
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s ease;

        .dxbl-carousel-pages-inner-container {
            padding: var(--dxbl-carousel-pages-padding-y) var(--dxbl-carousel-pages-padding-x);
            background-color: var(--dxbl-carousel-pages-background-color);
            opacity: var(--dxbl-carousel-pages-background-opacity);
            border-radius: var(--dxbl-carousel-pages-background-border-radius);
            font-size: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .dxbl-carousel-page {
            width: var(--dxbl-carousel-page-size);
            height: var(--dxbl-carousel-page-size);
            text-align: center;
            background-color: var(--dxbl-carousel-page-background-color);
            border-radius: 100%;
            display: inline-block;
            margin: var(--dxbl-carousel-page-margin);
            opacity: var(--dxbl-carousel-page-opacity);
            align-self: center;
        }

        .dxbl-carousel-page.dxbl-page-active,
        .dxbl-carousel-page.dxbl-page-active:hover {
            background-color: var(--dxbl-carousel-page-active-background-color);
            opacity: var(--dxbl-carousel-page-hover-opacity-active);
        }

        .dxbl-carousel-page:hover {
            cursor: pointer;
            opacity: var(--dxbl-carousel-page-hover-opacity);
        }
    }

    .dxbl-carousel-pages.dxbl-hide-buttons {
        opacity: 0;
    }

    .dxbl-carousel-pages.dxbl-show-buttons {
        opacity: 1;
    }
}
